import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
export interface IConsultingProps {
}

const hemletData = {
    title: "SPENANDE - IT Consulting",
    description: "Cloud | Web | API | Software"
}

export default function Consulting(props: IConsultingProps) {
    const theme = useTheme();

    useEffect(() => {
        let vid: HTMLVideoElement = document.getElementById("vid") as HTMLVideoElement;
        vid.playbackRate = 0.3;
    }, []);

    return (
        <>
            <Helmet>
                <title>{hemletData.title}</title>
                <link rel="canonical" href="/consulting"></link>
                <meta name="description" content={hemletData.description}></meta>
                <meta property="og:image" content="/cons.jpg" />
                <meta property="og:title" content={hemletData.title} />
                <meta property="og:url" content="/consulting" />
                <meta property="og:description" content={hemletData.description} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div dangerouslySetInnerHTML={{
                __html: `
                            <video
                            id="vid"
                            loop
                            muted
                            autoplay
                            playsinline
                            style="position:absolute; z-index:-1;object-fit: cover;width:100%;height:60vh;"
                            >
                                <source src="cons.mp4" type="video/mp4" />
                                <source src="cons.webm" type="video/webm" />
                                <img src="cons.jpg" alt="consulting" title="Your browser does not support the <video> tag"></img>
                            </video>`
            }}
            />
            <Box maxWidth="md" sx={{ p: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60vh', ml: 'auto', mr: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
                <Typography variant="h3">
                    IT Consulting
                </Typography>
                <Typography variant="caption">
                    Hand-picked experts only.
                </Typography>
            </Box>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem' }}>
                    <Typography variant="body1">
                    All of our consultants have 10+ years of hands-on experience in software development and systems architecture. 
                    We specialize in modern Microsoft technologies and lightweight frontend frameworks.
                    Our approach is pragmatic, focusing on what best addresses your specific needs while minimizing unnecessary complexity.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ padding: '1rem' }}>
                    <Typography variant="body1">
                        Contact us for more information about availability and pricing.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ padding: '1rem', textAlign: 'center', mt: 2, mb: 10 }}>
                    <Button component={Link} to={'/contact'} variant="outlined" type="submit" endIcon={<SendIcon />} sx={{ width: '200px' }}>
                        Get in touch
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}