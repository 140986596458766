import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { IJobOffer } from '../components/IJobOffer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function JobList() {
    const theme = useTheme();
    const [offers, setOffers] = useState<IJobOffer[]>([]);

    useEffect(() => {
        getOffers();

        return () => {
            setOffers([]);
        }
    }, []);

    async function getOffers() {
        try {
            const response = await fetch('/api/secure/jobs');
            const payload = await response.json();

            if (payload) {
                setOffers(payload);
            }
        } catch (error: any) {
            console.error('Error when fetching job offers ' + error?.message?.toString());
        }
    };

    return (
        <>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                    <Box maxWidth="sm">
                        <Button component={Link} to="/admin/jobedit/add">Add job</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {offers && <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell align="right">Technology</TableCell>
                                <TableCell align="right">Salary</TableCell>
                                <TableCell align="right">Active</TableCell>
                                <TableCell align="right">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {offers.map((offer) => (
                                <TableRow
                                    key={offer.rowKey}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {offer.title}
                                    </TableCell>
                                    <TableCell align="right">{offer.technology}</TableCell>
                                    <TableCell align="right">{offer.salaryMin}-{offer.salaryMax} {offer.currency}</TableCell>
                                    <TableCell align="right">
                                        {offer.isActive ? <VisibilityIcon htmlColor='green' /> : <VisibilityOffIcon />}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button component={Link} to={"/admin/jobedit/" + offer.rowKey}>Edit</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                </Grid>
            </Grid>
        </>
    );
}
