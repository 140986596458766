import { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { IJobOffer } from './IJobOffer';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
export interface IJobEditFormProps {
    RowKey?: string;
}

export default function JobEditForm(props: IJobEditFormProps) {
    const isAddMode = !props.RowKey;
    const [apiResponse, setApiResponse] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(!isAddMode);
    const [isActive, setIsActive] = useState(true);
    const [technology, setTechnology] = useState("Generic");

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Please provide job title')
            .max(200, 'Job title must not exceed 200 characters'),
        isActive: Yup.bool(),
        shortDescription: Yup.string()
            .required('Please provide short description')
            .max(10000, 'Description must not exceed 300 characters'),
        description: Yup.string()
            .required('Please provide description')
            .max(10000, 'Description must not exceed 10000 characters'),
        technology: Yup.string()
            .required('Please provide tech'),
        location: Yup.string()
            .required('Please provide location'),
        salaryMin: Yup.number().min(1).max(1000000).nullable(true),
        salaryMax: Yup.number().min(1).max(1000000).nullable(true),
        currency: Yup.string()
            .required('Please provide currency'),
        tags: Yup.string()
            .required('Please provide tags')
            .max(1000, 'Tags must not exceed 1000 characters'),
    });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const loadOffer = useCallback(async () => {
        setIsLoading(true);

        try {
            const response = await fetch('/api/secure/jobs/' + String(props.RowKey));
            const payload = await response.json();

            if (payload) {
                var offer = payload as IJobOffer;
                if (offer !== null) {
                    setValue('title', offer.title);
                    setValue('shortDescription', offer.shortDescription);
                    setValue('description', offer.description);
                    setValue('location', offer.location);
                    setValue('technology', offer.technology);
                    setTechnology(offer.technology);
                    setValue('tags', offer.tags);
                    setValue('salaryMin', offer.salaryMin);
                    setValue('salaryMax', offer.salaryMax);
                    setValue('currency', offer.currency);
                    setValue('isActive', offer.isActive);
                    setIsActive(offer.isActive);
                }
            }
        } catch (error: any) {
            setApiResponse(error);
            console.error('Error when fetching job offers ' + error?.message?.toString());
        }

        setIsLoading(false);
    }, [props.RowKey, setValue]);

    useEffect(() => {
        if (!isAddMode) {
            loadOffer();
        }

        return () => { }
    }, [isAddMode, loadOffer]);

    function onSubmit(data: any) {
        return isAddMode
            ? create(data)
            : update(String(props.RowKey), data);
    }

    const create = async (data: IJobOffer) => {
        var res = await fetch('/api/secure/jobs', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (res.status === 201) {
            setApiResponse("");
            setMessage("Created successfully");
            let location = res.headers.get('location');
            if (location) {
                navigate(location);
            }
        }
        else {
            setApiResponse("Something went wrong. Please try again later.");
            setMessage("");
        }
    };

    const update = async (rowKey: string, data: IJobOffer) => {
        var res = await fetch('/api/secure/jobs/' + rowKey, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (res.status === 202) {
            setApiResponse("");
            setMessage("Saved successfully");
        }
        else {
            setApiResponse("Something went wrong. Please try again later.");
            setMessage("");
        }
    };

    const handleIsAvtiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue('isActive', event.target.checked);
        setIsActive(event.target.checked);
    };

    const handleTechChange = (event: SelectChangeEvent) => {
        setTechnology(event.target.value);
        setValue('technology', event.target.value);
    };

    return (
        <>{isLoading ? <div>Loading...</div> :
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} maxWidth="sm">
                    <Grid item xs={12} sx={{ textAlign: 'center' }} >
                        <Typography variant="button" fontSize="large" textAlign="center" color="primary">
                            Job
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="title"
                            label="Title"
                            variant="filled"
                            fullWidth
                            {...register('title', { required: true })}
                            error={errors.title ? true : false}
                            helperText={errors.title?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="shortDescription"
                            label="Short description"
                            multiline
                            fullWidth
                            rows={5}
                            variant="filled"
                            {...register('shortDescription', { required: true })}
                            error={errors.shortDescription ? true : false}
                            helperText={errors.shortDescription?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="description"
                            label="Description"
                            multiline
                            fullWidth
                            rows={10}
                            variant="filled"
                            {...register('description', { required: true })}
                            error={errors.description ? true : false}
                            helperText={errors.description?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="filled" >
                            <InputLabel id="tech-label">Technology</InputLabel>
                            <Select
                                id="technology"
                                labelId="tech-label"
                                defaultValue='Generic'
                                value={technology}
                                onChange={handleTechChange}
                            >
                                <MenuItem value="NET">.NET</MenuItem>
                                <MenuItem value="NETCore">.NET Core</MenuItem>
                                <MenuItem value="Java">Java</MenuItem>
                                <MenuItem value="JavaScript">JavaScript</MenuItem>
                                <MenuItem value="Data">Data</MenuItem>
                                <MenuItem value="UXUI">UX/UI</MenuItem>
                                <MenuItem value="Support">Support</MenuItem>
                                <MenuItem value="BlockChanin">Blockchanin</MenuItem>
                                <MenuItem value="Python">Python</MenuItem>
                                <MenuItem value="C">C</MenuItem>
                                <MenuItem value="CPP">C++</MenuItem>
                                <MenuItem value="Mobile">Mobile</MenuItem>
                                <MenuItem value="Game">Games</MenuItem>
                                <MenuItem value="BA">Busienss analysis</MenuItem>
                                <MenuItem value="React">React</MenuItem>
                                <MenuItem value="Angular">Angular</MenuItem>
                                <MenuItem value="Test">Testing</MenuItem>
                                <MenuItem value="DevOps">DevOps</MenuItem>
                                <MenuItem value="PM">Project management</MenuItem>
                                <MenuItem value="PHP">PHP</MenuItem>
                                <MenuItem value="Generic">Generic</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="location"
                            label="Location"
                            variant="filled"
                            fullWidth
                            {...register('location', { required: true })}
                            error={errors.location ? true : false}
                            helperText={errors.location?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="salaryMin"
                            label="Salary min"
                            variant="filled"
                            {...register('salaryMin')}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            error={errors.salaryMin ? true : false}
                            helperText={errors.salaryMin?.message?.toString()}
                            sx={{ mr: '0.5rem', minWidth: '80px' }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="salaryMax"
                            label="Salary max"
                            variant="filled"
                            {...register('salaryMax')}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            error={errors.salaryMax ? true : false}
                            helperText={errors.salaryMax?.message?.toString()}
                            sx={{ mr: '0.5rem', minWidth: '80px' }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="currency"
                            label="Currency"
                            variant="filled"
                            {...register('currency')}
                            error={errors.currency ? true : false}
                            helperText={errors.currency?.message?.toString()}
                            sx={{ mr: '0.5rem', minWidth: '80px', maxWidth: '120px' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            id="tags"
                            label="Tags"
                            variant="filled"
                            fullWidth
                            {...register('tags', { required: true })}
                            error={errors.tags ? true : false}
                            helperText={errors.tags?.message?.toString()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox onChange={handleIsAvtiveChange} checked={isActive} id="isActive" inputProps={{ 'aria-label': 'Is active' }} />
                        <span>Active</span>
                    </Grid>
                    <Grid item xs={12}>
                        {apiResponse?.length > 0 ? <Alert severity="error">{apiResponse}</Alert> : ''}
                        {message?.length > 0 ? <Alert severity="success">{message}</Alert> : ''}
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button variant="outlined" sx={{ width: '150px', mr: '1rem' }} component={Link} to="/admin/joblist">
                            Back
                        </Button>
                        <Button variant="outlined" type="submit" endIcon={<SaveIcon />} sx={{ width: '150px' }}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>}
        </>
    );
}