import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import logo from '../logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { pages, adminPages } from './Pages';

export interface IResponsiveAppBarProps {
    user: string | null
}

const ResponsiveAppBar = (props: IResponsiveAppBarProps) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = useNavigate();
    const handleHomeClick = () => {
        navigate('/');
    };

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        component="img"
                        sx={{
                            height: 48,
                            width: 48,
                            mr: 2,
                            cursor: 'pointer'
                        }}
                        onClick={handleHomeClick}
                        alt="SPENANDE logo"
                        src={logo}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        onClick={handleHomeClick}
                        sx={{ mr: 2, display: 'flex', cursor: 'pointer', fontWeight: 900, flexGrow: { xs: 1, md: 0 } }}
                    >
                        SPENANDE
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.title}
                                color='primary'
                                onClick={handleCloseNavMenu}
                                component={Link}
                                to={page.url}
                                sx={{ my: 2, display: 'block' }}
                            >
                                {page.title}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.title} onClick={handleCloseNavMenu} component={Link} to={page.url}>
                                    <Typography textAlign="center" color='primary'>{page.title}</Typography>
                                </MenuItem>
                            ))}
                            <Divider light />
                            {props.user && adminPages.map((page) => (
                                <MenuItem key={page.title} onClick={handleCloseNavMenu} component={Link} to={page.url}>
                                    <Typography textAlign="center" color='primary'>{page.title}</Typography>
                                </MenuItem>
                            ))}
                            {!props.user && <MenuItem key="Login" onClick={handleCloseNavMenu} component="a" href="/login">
                                <Typography textAlign="center" color='primary'>Login</Typography>
                            </MenuItem>}
                            {props.user && <MenuItem key="Logout" onClick={handleCloseNavMenu} component="a" href="/logout">
                                <Typography textAlign="center" color='primary'>Logout</Typography>
                            </MenuItem>}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;