import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DoneIcon from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

interface Inputs {
    email: string;
    message: string;
    acceptTerms: boolean;
}

export interface IContactFormProps {
}

export default function ContactForm(props: IContactFormProps) {
    const [submitted, setSumbitted] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const theme = useTheme();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please provide an Email address')
            .max(200, 'Email must not exceed 200 characters')
            .email('Email address is invalid'),
        message: Yup.string()
            .required('Please type your message')
            .max(10000, 'Message must not exceed 10000 characters'),
        acceptTerms: Yup.bool().oneOf([true], 'Please accept privacy policy')
    });

    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: Inputs) => {
        var res = await fetch('api/contactmessage', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if(res.status === 201){
            setApiResponse("");
            setSumbitted(true);
        }
        else{
            setApiResponse("Something went wrong. Please try again later.")
        }
    };

    return (
        <>
            {(!submitted || apiResponse.length>0) && <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1} maxWidth="sm">
                    <Grid item xs={12} sx={{ textAlign: 'center' }} >
                        <Typography variant="button" fontSize="large" textAlign="center" color="primary">
                            Contact us
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="email"
                            label="Email address"
                            variant="filled"
                            fullWidth
                            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="message"
                            label="Message"
                            multiline
                            fullWidth
                            rows={10}
                            variant="filled"
                            {...register('message', { required: true })}
                            error={errors.message ? true : false}
                            helperText={errors.message?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Checkbox {...register('acceptTerms')} id="acceptTerms" inputProps={{ 'aria-label': 'Accept privacy policy' }} />
                        <span>I accept <Link to='/privacy' style={{cursor: 'pointer', color: theme.palette.primary.main}}>Privacy policy</Link>.</span>
                            <br/>
                        <span>When subbmitting this form you agree upon the fact that Spenande sp. z o.o. 
                            will store and keep your personal data as long as required to evaluate 
                            the information provided in the form and in order to be able to contact you back.
                        </span>
                        <FormHelperText error={errors.acceptTerms ? true : false}>{errors.acceptTerms?.message}</FormHelperText>
                        <FormHelperText error={apiResponse.length>0 ? true : false}>{apiResponse}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button variant="outlined" type="submit" endIcon={<SendIcon />} sx={{ width: '150px' }}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </form>}
            {apiResponse.length===0 && submitted && <Grid container spacing={3} maxWidth="sm">
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <DoneIcon color="primary" fontSize="large" />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <Typography variant="body1" textAlign="center">
                        Thank you for contacting SPENANDE. Your message has been sent.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }} >
                    <Button component={Link} to={'/'} variant="outlined" color="primary">Back to homepage</Button>
                </Grid>
            </Grid>}
        </>
    );
}
