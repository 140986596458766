import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import JobEditForm from '../components/JobEditForm';
import { useParams } from 'react-router-dom';

export default function JobEdit() {
    let { id } = useParams();
    const theme = useTheme();
    
    return (
        <>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem', pt: '4rem', pb: '4rem', alignItems: 'center', justifyContent: 'center' }}>
                    <Box maxWidth="sm" sx={{ margin: 'auto' }}>
                        <JobEditForm RowKey={id}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
