import ResponsiveAppBar from './ResponsiveAppBar';
import Container from '@mui/material/Container';

export interface IHeaderProps {
    user: string | null
}

export default function Header(props: IHeaderProps) {
    return (
        <>
            <ResponsiveAppBar user={props.user} />
            <Container sx={{
                height: { xs: '56px', sm: '64px', md: '68px' }
            }}>
            </Container>
        </>
    );
}